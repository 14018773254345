<template>
  <div id="app">
    <router-view />
    <alert
      :header="getAlertHeaderText"
      :show-alert="getAlertShow"
      @continue-action="hideAlert()"
    >
      {{getAlertText}}
    </alert>
  </div>
</template>
<script>
import Alert from './components/Alert.vue';
import '@progress/kendo-theme-default/dist/all.css';

export default {
  name: 'App',
  components: { Alert },
  computed: {
    getAlertText() {
      return this.$store.state.globalAlertConfig.text;
    },
    getAlertShow() {
      return this.$store.state.globalAlertConfig.show;
    },
    getAlertHeaderText() {
      return this.$store.state.globalAlertConfig.headerText;
    },
  },
  created() {
    window.onbeforeunload = this.confirmLeaving;
  },
  mounted() {
    if (!this.$store.getters.getCheckAuthInterval()) {
      this.$store.dispatch('checkStillAuthenticated');
    }
  },
  methods: {
    confirmLeaving() {
      if (
        this.$route.name !== 'map'
        && (this.$store.getters.getIsDirtyCustomerFilters()
        || this.$store.getters.getModalOpen())) {
        return 'Are you sure you would like to leave';
      }
      return null;
    },
    hideAlert() {
      this.$store.commit('mutateGlobalAlertConfig', {
        show: false,
        headerText: null,
        text: null,
      });
    },
  },
};
</script>
