import VueGtm from 'vue-gtm';
import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login',
    },
    beforeEnter: () => {
      window.location.href = '/sso';
    },
  },
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Home',
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      if (to.path === '/') {
        axios.get('/api/global/check-auth').then(() => {
          store.dispatch('fetchInitialRequests');
          next({ name: 'dashboard' });
        })
          .catch(() => {
            next({ name: 'login' });
            store.commit('mutateIsLoggedIn', false);
            store.commit('mutateClearCheckAuthInterval');
            // clear the 401 error from console
            console.clear();
          });
      } else {
        next();
      }
    },
    children: [
      {
        path: '/map',
        name: 'map',
        meta: {
          title: 'Map',
        },
        component: () => import(/* webpackChunkName: "related" */ '../views/Map.vue'),
      },
      {
        path: '/dashboard',
        meta: {
          title: 'Dashboard',
        },
        component: () => import(/* webpackChunkName: "related" */ '../views/DashboardWrapper.vue'),
        children: [
          {
            path: '',
            name: 'dashboard',
            meta: {
              title: 'Dashboard',
            },
            component: () => import(/* webpackChunkName: "mydash" */ '../views/Dashboard.vue'),
          },
          {
            path: 'account',
            name: 'dashboard_account',
            meta: {
              title: 'Account',
            },
            component: () => import(/* webpackChunkName: "account" */ '../views/DashboardAccount.vue'),
          },
        ],
      },
      {
        path: '/customers',
        name: 'customers',
        meta: {
          title: 'Customers',
        },
        component: () => import(/* webpackChunkName: "related" */ '../views/Customers.vue'),
        children: [
          {
            path: ':id/interactions',
            name: 'customer_interactions',
            meta: {
              title: 'Interactions',
            },
            component: () => import(/* webpackChunkName: "interactions" */ '../views/CustomerInteractions.vue'),
          },
          {
            path: ':id/profile',
            name: 'customer_profile',
            meta: {
              title: 'Profile',
            },
            component: () => import(/* webpackChunkName: "profile" */ '../views/CustomerProfile.vue'),
          },
          {
            path: ':id/negotiations',
            name: 'customer_negotiations',
            meta: {
              title: 'Negotiations',
            },
            component: () => import(/* webpackChunkName: "payments" */ '../views/CustomerNegotiations.vue'),
          },
          {
            path: ':id/negotiations/:negotiationId/contract',
            name: 'customer_negotiation_contract',
            meta: {
              title: 'Negotiation Contract',
            },
            component: () => import(/* webpackChunkName: "contract" */ '../views/CustomerNegotiationContract.vue'),
          },
          {
            path: ':id/grower-services',
            name: 'customer_grower_services',
            meta: {
              title: 'Grower Services',
            },
            component: () => import(/* webpackChunkName: "profile" */ '../views/GrowerServices.vue'),
          },
          {
            path: ':id/contacts',
            name: 'customer_contacts',
            meta: {
              title: 'Contacts',
            },
            component: () => import(/* webpackChunkName: "contacts" */ '../views/CustomerContacts.vue'),
          },
          {
            path: ':id/orders',
            name: 'customer_orders',
            meta: {
              title: 'Orders',
            },
            component: () => import(/* webpackChunkName: "orders" */ '../views/CustomerOrders.vue'),
          },
          {
            path: ':id/payments',
            name: 'customer_payments',
            meta: {
              title: 'Payments',
            },
            component: () => import(/* webpackChunkName: "payments" */ '../views/CustomerPayments.vue'),
          },
          {
            path: ':id/legal',
            name: 'customer_legal',
            meta: {
              title: 'Legal Proceeding',
            },
            component: () => import(/* webpackChunkName: "legal" */ '../views/CustomerLegal.vue'),
          },
          {
            path: ':id/related',
            name: 'customer_related',
            meta: {
              title: 'Related',
            },
            component: () => import(/* webpackChunkName: "related" */ '../views/CustomerRelated.vue'),
          },
        ],
      },
      // 404 and fun handling
      {
        path: 'beer-facts',
        name: 'beer_facts',
        component: () => import(/* webpackChunkName: "beer-facts" */ '../views/Error.vue'),
      },
      // note: this must always come last because it handles all unresolved/error routes
      {
        path: '*',
        name: 'error',
        component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Fetches header data for each customer page if not loaded
  if (to.name.startsWith('customer_')) {
    store.dispatch('fetchUnloadedData', to.params.id);
  }

  // set the meta title accordingly
  document.title = `YCRM ${to.meta.title ? to.meta.title : to.name}`;

  next();
});

Vue.use(VueGtm, {
  id: 'GTM-TD7WXFT',
  vueRouter: router,
});

export default router;
