/* eslint-disable import/order, max-len */
import Vue from 'vue';
import axios from 'axios';
import * as FullStory from '@fullstory/browser';
import App from './App.vue';
import router from './router';
import store from './store';
import './stylesheets/main.scss';

// DataDog
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressBook,
  faAddressCard,
  faBalanceScale,
  faBan,
  faBars,
  faBeer,
  faBold,
  faBuilding,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCheck,
  faChevronCircleDown,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCity,
  faCog,
  faCoins,
  faComments,
  faDollarSign,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileContract,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFilter,
  faGavel,
  faGlobeAmericas,
  faHandshake,
  faHome,
  faItalic,
  faKey,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faMap,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faMinusCircle,
  faMoneyCheckAlt,
  faPaperclip,
  faPhone,
  faPhoneAlt,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faRedo,
  faSave,
  faSearch,
  faShareAlt,
  faShieldAlt,
  faShoppingCart,
  faSignOutAlt,
  faSpinner,
  faStarHalfAlt,
  faStickyNote,
  faStoreSlash,
  faTag,
  faTimes,
  faTrashAlt,
  faUnderline,
  faUndo,
  faUserCircle,
  faInfoCircle,
  faTable,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/**
 * FullStory
 */

FullStory.init({
  orgId: process.env.VUE_APP_FULLSTORY_ORG_ID,
});

Vue.prototype.$FullStory = FullStory;

/**
 * DataDog
 */

const ddConfig = {
  clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'ycrm-web',
  env: process.env.NODE_ENV || 'production',
  sampleRate: 100,
};

// init datadog browser logging
datadogLogs.init(ddConfig);

// init datadog user monitoring
datadogRum.init({
  ...ddConfig,
  applicationId: process.env.VUE_APP_DD_APP_ID,
  trackInteractions: true,
});

// app tools
Vue.mixin({
  methods: {
    // eslint-disable-next-line consistent-return
    DataDogLogger(message, data, type = 'info') {
      const toolName = 'DataDog Logger';
      const logTypeArray = ['debug', 'info', 'warn', 'error'];
      const messagePrefix = () => {
        switch (type) {
          case 'debug':
            return 'Debug';
          case 'warn':
            return 'Warning';
          case 'error':
            return 'Error';
          default:
            return 'Information';
        }
      };

      // fail if no required values are passed
      if (!message || !data) {
        console.error(`${toolName}: You must supply a message string and data object.`);

        return false;
      }

      // fail if data is not an object
      if (typeof data !== 'object' && data !== null) {
        console.error(`${toolName}: Your data must be an object!`);

        return false;
      }

      // fail if the type does not match one of the required values
      if (logTypeArray.includes(type) === false) {
        console.error(`${toolName}: The type must be debug, info, warn, or error! You used ${type}.`);

        return false;
      }

      datadogLogs.logger[type](`${messagePrefix()}: ${message}`, data);
    },
  },
});

/**
 * See https://github.com/FortAwesome/vue-fontawesome#why-use-the-concept-of-a-library
 */
library.add(
  faAddressBook,
  faAddressCard,
  faBalanceScale,
  faBan,
  faBars,
  faBeer,
  faBold,
  faBuilding,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCheck,
  faChevronCircleDown,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCity,
  faCog,
  faCoins,
  faComments,
  faDollarSign,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileContract,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFilter,
  faGavel,
  faGlobeAmericas,
  faHandshake,
  faHome,
  faItalic,
  faKey,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faMap,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faMinusCircle,
  faMoneyCheckAlt,
  faPaperclip,
  faPhone,
  faPhoneAlt,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faRedo,
  faSave,
  faSearch,
  faShareAlt,
  faShieldAlt,
  faShoppingCart,
  faSignOutAlt,
  faSpinner,
  faStarHalfAlt,
  faStickyNote,
  faStoreSlash,
  faTag,
  faTimes,
  faTrashAlt,
  faUnderline,
  faUndo,
  faUserCircle,
  faInfoCircle,
  faTable,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

/**
 * Axios
 */

axios.defaults.withCredentials = true;

// global response handling
axios.interceptors.response.use((res) => res, (error) => {
  const currentAppUrl = router.history.current.name;
  const { status, data } = error.response;

  if (currentAppUrl !== 'login') {
    // 401: Unauthenticated
    // 419: XSRF mismatch
    if (status === 401 || data.message === 'Unauthenticated' || status === 419) {
      router.push({ name: 'login' });
    }
  }

  // push to datadog
  const vm = new Vue();
  vm.DataDogLogger(
    `Failed API Request - ${error.config.url}`,
    {
      url: error.config.url,
      payload: {
        ...data,
      },
    },
    'error',
  );

  return Promise.reject(error);
});

/*
  Leaving block commented in anticipation of detecting mobile users and linking them
  to mobile app store
 */
// Serve mobile version of site if touchscreen and screen >600px
// eslint-disable-next-line no-unused-vars
// let hasTouchScreen = false;
// const mql = window.matchMedia('(max-width: 700px)');
// if ('maxTouchPoints' in navigator) {
//   hasTouchScreen = navigator.maxTouchPoints > 0;
// } else if ('msMaxTouchPoints' in navigator) {
//   hasTouchScreen = navigator.msMaxTouchPoints > 0;
// } else {
//   const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
//   if (mQ && mQ.media === '(pointer:coarse)') {
//     hasTouchScreen = !!mQ.matches;
//   } else if ('orientation' in window) {
//     hasTouchScreen = true; // deprecated, but good fallback
//   } else {
//     // Only as a last resort, fall back to user agent detection
//     const UA = navigator.userAgent;
//     hasTouchScreen = (
//       /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA)
//       || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
//     );
//   }
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
