<template>
  <div class="alert" v-if="showAlert">
    <div class="alert__content">
      <div class="alert__header">
        <h2 class="font-bold">{{header}}</h2>
      </div>
      <hr>
      <div class="alert__body">
        <slot />
      </div>
      <div class="alert__btn-dual" v-if="cancelAndConfirm">
        <button class="alert__btn-cancel" @click="closeAlert">Cancel</button>
        <button class="alert__btn-continue" @click="continueAction">Continue</button>
      </div>
      <div class="alert__btn-single" v-if="!cancelAndConfirm">
        <button class="alert__btn-ok" @click="continueAction">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    header: String,
    cancelAndConfirm: {
      type: Boolean,
      default: false,
      required: false,
    },
    showAlert: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    closeAlert() {
      this.$emit('cancel');
    },
    continueAction() {
      this.$emit('continue-action');
    },
  },
};
</script>
