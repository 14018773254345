import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: true,
    hideDropdown: true,
    globalConfig: null,
    negotiationConfig: null,
    checkAuthInterval: null,
    currentPage: null,
    customerIndex: null,
    fullCustomerIndex: null,
    fullCustomerTotal: null,
    customerHeaderInfo: null,
    currentCustomer: null,
    customerRelationsInfo: null,
    currentUser: null,
    allUsers: null,
    customerInfo: null,
    customerInteractionsInfo: null,
    customerNegotiationsInfo: {
      negotiations: [],
      page_data: {
        total_items: null,
        current_page: 1,
        last_page: 1,
        has_next: false,
      },
    },
    growerInteractionsInfo: null,
    loginTimeout: false,
    interactionModalOpen: false,
    negotiationModalOpen: false,
    contractDetailsModalOpen: false,
    formModelStoredNamespace: 'ycrmCustomerFormModel.v2.1',
    myAccountsStoredNamespace: 'ycrmFilterByMyAccounts.v2.1',
    myFavoritesStoredNamespace: 'ycrmFilterByMyFavorites.v2.1',
    advancedCustomerFilters: null,
    advancedCustomerFiltersModalOpen: false,
    advancedCustomerFiltersApplied: false,
    customerTypedFilter: null,
    myAccountsInputVal: true,
    myFavoritesInputVal: true,
    q: null,
    customerFilters: {
      rep: null,
      territory: null,
      grade: null,
      tags: null,
      type: null,
      from: null,
      to: null,
      showing: 'both',
      myAccounts: 1,
      myFavorites: 1,
    },
    updateUserModalOpen: false,
    currentNegotiation: null,
    currentContractYear: null,
    currentInteraction: {
      date: null,
      type: null,
      tags: null,
      categories: [],
      categoriesRaw: [],
      text: null,
      fileName: null,
      growerServicesUpdate: false,
      fileIcon: null,
      fileUrl: null,
      target: null,
    },
    globalAlertConfig: {
      show: false,
      headerText: null,
      text: null,
    },
    advancedCustomerProfile: null,
    assembledCustomerProfile: null,
    followupData: null,
    followupFilters: null,
    isIndexFetching: false,
    isFullIndexFetching: false,
    customerContacts: null,
    customerFocusOnMap: null,
    customerFieldLexicon: {
      id: 'ID',
      customer_id: 'Customer ID',
      website_url: 'Website',
      average_lb_per_barrel: 'Avg. Lb. Per Barrel',
      distribution_style: 'Distribution Style',
      available_beer_state_count: 'States Where Available',
      contract_brewing: 'Contract Brewing',
      contract_brewing_notes: 'Contract Brewing Notes',
      uses_contract_brewing: 'Uses Contract Brewing',
      uses_contract_brewing_notes: 'Contract Brewing Use Notes',
      shop_price: 'Shops Price',
      mobile_canning: 'Mobile Canning',
      sales_percentage_taproom: 'Taproom Sales',
      sales_percentage_on_premise: 'On-Premise Sales',
      sales_percentage_off_premise: 'Off-Premise Sales',
      sales_percentage_draught: 'Draft Sales',
      sells_draft: 'Beer on Draft',
      sells_cans: 'Cans',
      sells_bottles: 'Bottles',
      sells_kegs: 'Kegs',
      fills_crowlers: 'Crowlers',
      fills_growlers: 'Growlers',
      pilot_system: 'Pilot System',
      sour_program: 'Sour Program',
      barrel_age_program: 'Barrel Age Program',
      seltzer_program: 'Seltzer Program',
      cider_program: 'Cider Program',
      non_alcoholic_program: 'Non-Alcoholic Program',
      selection_customer: 'Participates in Selection',
      research_and_development: 'R & D',
      open_to_collaboration: 'Open to Collab',
      vocal_in_industry: 'Vocal',
      organic_designation: 'Organic Designation',
      organic_designation_notes: 'Organic Designation Notes',
      sustainable: 'Sustainable',
      sustainable_notes: 'Sustainability Notes',
      community_give_back: 'Gives Back to Community',
      community_give_back_notes: 'Community Give-Back Notes',
      relationships: 'Relationships',
      notes: 'Notes',
      created_at: 'Created',
      updated_at: 'Updated',
      deleted_at: 'Deleted',
      location_count: 'Location Count',
      days_old: 'Contract Age',
    },
    currentUserNotifications: null,
    currentUserNotificationSettings: null,
    currentUserNotificationPreferences: null,
    mapIsReady: false,
    customerModalRangeResetKey: 0,
    currentUserMetrics: null,
    userMetricsLoading: true,
    appliedFiltersMatchStored: false,
    sidebarCustomerListIsFetching: false,
    customerFilterFormIsMounted: false,
    dashboard: {
      followUpsLoaded: false,
      followUpsError: false,
      notificationsLoaded: false,
      notificationsError: false,
      freshnessLoaded: false,
      freshnessError: false,
      metricsLoaded: false,
      metricsError: false,
    },
  },
  mutations: {
    mutateNegotiationConfig(state, data) {
      state.negotiationConfig = data;
    },
    mutateGlobalConfig(state, data) {
      state.globalConfig = data;
    },
    mutateGlobalAlertConfig(state, data) {
      state.globalAlertConfig = data;
    },
    mutateCheckAuthInterval(state, data) {
      state.checkAuthInterval = data;
    },
    mutateClearCheckAuthInterval(state) {
      clearInterval(state.checkAuthInterval);
      state.checkAuthInterval = null;
    },
    mutateLoginTimeout(state, data) {
      state.loginTimeout = data;
    },
    mutateCustomerIndex(state, data) {
      state.customerIndex = data;
    },
    mutateFullCustomerIndex(state, data) {
      state.fullCustomerIndex = data;
    },
    mutateCustomerHeaderInfo(state, data) {
      state.customerHeaderInfo = data;
    },
    mutateCurrentCustomer(state, data) {
      state.currentCustomer = data;
    },
    mutateCurrentUser(state, data) {
      state.currentUser = data;
    },
    mutateAllUsersList(state, data) {
      state.allUsers = data;
    },
    mutateCustomerInteractionsInfo(state, data) {
      state.customerInteractionsInfo = data;
    },
    mutateCustomerNegotiationsInfo(state, data) {
      state.customerNegotiationsInfo = data;
    },
    mutateGrowerInteractionsInfo(state, data) {
      state.growerInteractionsInfo = data;
    },
    mutateCustomerRelationsInfo(state, data) {
      state.customerRelationsInfo = data;
    },
    mutateIsLoggedIn(state, data) {
      state.isLoggedIn = !!data;
    },
    mutateCustomerInfo(state, data) {
      state.customerInfo = data;
    },
    mutateOpenUpdateUserModal(state) {
      state.updateUserModalOpen = true;
    },
    mutateCloseUpdateUserModal(state) {
      state.updateUserModalOpen = false;
    },
    mutateOpenAdvancedCustomerFiltersModal(state) {
      state.advancedCustomerFiltersModalOpen = true;
    },
    mutateCloseAdvancedCustomerFiltersModal(state) {
      state.advancedCustomerFiltersModalOpen = false;
    },
    mutateIsIndexFetching(state, data) {
      state.isIndexFetching = !!data;
    },
    mutateOpenInteractionModal(state, interaction = null) {
      if (interaction !== null) {
        state.currentInteraction.type = interaction[0].id; // magic number for date column
        state.currentInteraction.date = interaction[1].date; // magic number for date column
        state.currentInteraction.text = interaction[3].text; // magic number for notes column
        state.currentInteraction.fileName = interaction[4].name; // magic number for file column
        state.currentInteraction.fileIcon = interaction[4].icon; // magic number for file column
        state.currentInteraction.grower_service_update = interaction[4].grower_service_update;
        state.currentInteraction.fileUrl = interaction[4].href; // magic number for file column
        state.currentInteraction.tags = interaction[5].list; // magic number for file column
        state.currentInteraction.categories = interaction[6].list; // magic number for file column
        state.currentInteraction.target = interaction[7].edit; // magic number for file column
        // eslint-disable-next-line prefer-destructuring
        state.currentInteraction.categoriesRaw = interaction[9];
        // eslint-disable-next-line prefer-destructuring
        state.currentInteraction.followUp = interaction[10];
      }
      state.interactionModalOpen = true;
    },
    mutateCloseInteractionModal(state) {
      state.currentInteraction.type = null;
      state.currentInteraction.date = null;
      state.currentInteraction.text = null;
      state.currentInteraction.fileName = null;
      state.currentInteraction.fileIcon = null;
      state.currentInteraction.fileUrl = null;
      state.currentInteraction.tags = null;
      state.currentInteraction.categories = [];
      state.currentInteraction.categoriesRaw = [];
      state.currentInteraction.target = null;
      state.currentInteraction.followUp = null;
      state.interactionModalOpen = false;
    },

    mutateOpenNegotiationModal(state, negotiation = null) {
      if (negotiation !== null) {
        const matchingNegotiations = state.customerNegotiationsInfo.negotiations
          .filter((element) => element.id === negotiation[10].id);
        const currentNegotiation = matchingNegotiations.shift();

        currentNegotiation.sales_site_id = currentNegotiation.sales_site?.id;
        currentNegotiation.contract_type_id = currentNegotiation.contract_type?.id;
        currentNegotiation.address_id = currentNegotiation.address?.id;
        currentNegotiation.payment_term_id = currentNegotiation.payment_term?.id;
        currentNegotiation.currency_id = currentNegotiation.currency?.id;
        currentNegotiation.customer_id = currentNegotiation.customer?.id;
        currentNegotiation.incoterm_id = currentNegotiation.incoterm?.id;
        state.currentNegotiation = currentNegotiation;
      }
      state.negotiationModalOpen = true;
    },

    mutateOpenContractDetailsModal(state, contractDetails = null) {
      if (contractDetails !== null) {
        state.currentContractYear = contractDetails;
      }
      state.contractDetailsModalOpen = true;
    },

    mutateCloseContractDetailsModal(state) {
      state.currentContractYear = null;
      state.contractDetailsModalOpen = false;
    },

    mutateViewNegotiationContract(state, negotiationId = null) {
      if (negotiationId !== null) {
        const matchingNegotiations = state.customerNegotiationsInfo.negotiations
          .filter((element) => element.id === negotiationId);
        const currentNegotiation = matchingNegotiations.shift();

        currentNegotiation.sales_site_id = currentNegotiation.sales_site?.id;
        currentNegotiation.contract_type_id = currentNegotiation.contract_type?.id;
        currentNegotiation.address_id = currentNegotiation.address?.id;
        currentNegotiation.payment_term_id = currentNegotiation.payment_term?.id;
        currentNegotiation.currency_id = currentNegotiation.currency?.id;
        currentNegotiation.customer_id = currentNegotiation.customer?.id;
        currentNegotiation.incoterm_id = currentNegotiation.incoterm?.id;

        state.currentNegotiation = currentNegotiation;
      }
      // state.negotiationModalOpen = true;
    },

    mutateCloseNegotiationModal(state) {
      state.currentNegotiation = {};
      state.currentNegotiation.id = null;
      state.currentNegotiation.contract_type = null;
      state.currentNegotiation.incoterm = null;
      state.currentNegotiation.sales_site = null;
      state.currentNegotiation.address = null;
      state.currentNegotiation.payment_term = null;
      state.currentNegotiation.currency = null;
      state.currentNegotiation.customer = null;
      state.currentNegotiation.contract_type_id = null;
      state.currentNegotiation.sales_site_id = null;
      state.currentNegotiation.incoterm_id = null;
      state.currentNegotiation.address_id = null;
      state.currentNegotiation.payment_term_id = null;
      state.currentNegotiation.currency_id = null;
      state.currentNegotiation.customer_id = null;
      state.currentNegotiation.is_selected = null;
      state.currentNegotiation.crop_year = null;
      state.currentNegotiation.representative = {};
      state.currentNegotiation.notes = null;
      state.currentNegotiation.reference = null;
      state.currentNegotiation.number = null;
      state.currentNegotiation.permissions = null;
      state.currentNegotiation.synced_to_x3 = null;
      state.currentNegotiation.created_at = null;
      state.currentNegotiation.negotiation_products = [{
        uid: `${Math.floor(Math.random() * 1000)}-${Date.now()}`,
        negotiation_id: null,
        product_id: null,
        analysis_method_id: null,
        competitor_product_id: null,
        list_price: null,
        net_price: null,
        discount: null,
        quantity: null,
        unit_of_measurement: null,
        win_loss: null,
        extension: null,
        created_at: null,
        competitor_product: {
          id: null,
          price: null,
          product: null,
          variety: null,
          created_at: null,
          updated_at: null,
        },
      }];
      state.negotiationModalOpen = false;
    },
    mutateAdvancedCustomerFilters(state, filters = {}) {
      state.advancedCustomerFilters = filters;
    },
    mutateCustomerFilters(state, filters = {}) {
      // Filters must be an object
      // eslint-disable-next-line no-unused-expressions
      Object.keys(filters).forEach((filter) => {
        const has = Object.prototype.hasOwnProperty;
        if (has.call(state.customerFilters, filter)) {
          state.customerFilters[filter] = filters[filter];
        }
      });
    },
    mutateCustomerQueryString(state, q) {
      state.q = q;
    },
    mutateAdvancedCustomerProfile(state, data) {
      state.advancedCustomerProfile = data;
    },
    mutateAssembledCustomerProfile(state, data) {
      const lexicon = state.customerFieldLexicon;
      const profile = data.customer_profile;
      const newData = {};

      if (profile && Object.entries(profile).length) {
        // exclude the fields that we don't want to count as booleans
        // these are fields we want to pass along as they are written
        const excludedFields = [
          'id',
          'customer_id',
          'website_url',
          'distribution_style',
          'average_lb_per_barrel',
          'organic_designation',
          'organic_designation_notes',
          'community_give_back_notes',
          'contract_brewing_notes',
          'uses_contract_brewing_notes',
          'sustainable_notes',
          'notes',
          'created_at',
          'updated_at',
          'deleted_at',
          'sales_percentage_taproom',
          'sales_percentage_on_premise',
          'sales_percentage_off_premise',
          'sales_percentage_draught',
          'location_count',
          'available_beer_state_count',
        ];

        Object.entries(profile).forEach((v) => {
          const slug = v[0];
          let val;

          if (!excludedFields.includes(slug)) {
            switch (v[1]) {
              case 0:
                val = 'No';
                break;
              case 1:
                val = 'Yes';
                break;
              default:
                val = 'Unknown';
            }
          } else {
            // eslint-disable-next-line prefer-destructuring
            val = v[1];
          }

          if (lexicon[slug]) {
            newData[slug] = {
              label: lexicon[slug],
              value: val,
            };
          }
        });
      }

      // location count
      if (data.addresses) {
        const locationCount = (data.addresses && data.addresses.length)
          ? data.addresses.length
          : 'None';

        newData.location_count = {
          label: lexicon.location_count, // get the human-readable label
          value: locationCount, // get the count
        };
      }

      state.assembledCustomerProfile = newData;
    },
    mutateFollowupData(state, data) {
      state.followupData = data;
    },
    mutateCustomerContacts(state, data) {
      state.customerContacts = data;
    },
    mutateCustomerFocusOnMap(state, data) {
      state.customerFocusOnMap = data;
    },
    mutateX3Types(state, data) {
      state.x3Types = data;
    },
    mutateCurrentUserNotifications(state, data) {
      state.currentUserNotifications = data;
    },
    mutateCurrentUserNotificationSettings(state, data) {
      state.currentUserNotificationSettings = data;
    },
    mutateCurrentUserNotificationPreferences(state, data) {
      state.currentUserNotificationPreferences = data;
    },
    mutateFollowUpFilters(state, data) {
      state.followupFilters = data;
    },
    mutateMyAccountsInputVal(state, data) {
      state.myAccountsInputVal = data;
    },
    mutateMyFavoritesInputVal(state, data) {
      state.myFavoritesInputVal = data;
    },
    mutateTotalCustomerCount(state, data) {
      state.fullCustomerTotal = data;
    },
    mutateAdvancedCustomerFiltersApplied(state, data) {
      state.advancedCustomerFiltersApplied = data;
    },
    mutateMapIsReady(state, data) {
      state.mapIsReady = data;
    },
    mutateCustomerModalRangeResetKey(state, data) {
      state.customerModalRangeResetKey = data;
    },
    mutateCurrentUserMetrics(state, data) {
      state.currentUserMetrics = data;
    },
    mutateMetricsAreLoading(state, data) {
      state.userMetricsLoading = data;
    },
    mutateAppliedFiltersMatchStored(state, data) {
      state.appliedFiltersMatchStored = data;
    },
    mutateSidebarCustomerListIsFetching(state, data) {
      state.sidebarCustomerListIsFetching = data;
    },
    mutateCustomerFilterFormIsMounted(state, data) {
      state.customerFilterFormIsMounted = data;
    },
    mutateDashboardStatus(state, data) {
      // make sure the proper keys exist on the data object
      if (!('target' in data) || !('payload' in data)) {
        console.error('`target` and `payload` are required on your object!', data);
      }

      // check to ensure `data` is an object
      if (typeof data !== 'object') {
        console.error('data must be an object!');
      }

      // check to ensure that the key supplied exists on the target object
      if (!data.target) {
        console.error('you must specify a target key to apply the changes to');
      }

      // check to ensure the supplied key exists on `dashboard`
      if (data.target && !(data.target in state.dashboard)) {
        console.error('the key you supplied does not exist on `dashboard`.');
      }

      // check to ensure that `data.payload` is a boolean
      if (data.payload && typeof data.payload !== 'boolean') {
        console.error('payload must be of type boolean!');
      }

      // if `target` and `payload` are present on our object and the `payload` is a boolean, proceed
      if (data.target && data.payload && data.target in state.dashboard && typeof data.payload === 'boolean') {
        state.dashboard[data.target] = data.payload;
      }
    },
  },
  actions: {
    checkStillAuthenticated(context) {
      const timeout = 600000;

      const interval = setInterval(() => {
        axios.get(`${process.env.VUE_APP_BASE_URL || ''}/api/global/check-auth`, {
          headers: {
            'x-api-key': process.env.VUE_APP_TOKEN || '',
          },
        })
          .catch(() => {
            context.commit('mutateIsLoggedIn', false);
            context.commit('mutateLoginTimeout', true);
            context.commit('mutateClearCheckAuthInterval');
          });
      }, timeout);

      context.commit('mutateCheckAuthInterval', interval);
    },
    fetchGlobalConfig(context) {
      context.commit('mutateGlobalConfig', null);
      axios.get('/api/global/config', {
        headers: {
          'x-api-key': process.env.VUE_APP_TOKEN || '',
        },
      })
        .then((res) => {
          context.commit('mutateGlobalConfig', res.data.data[0]);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Global Config',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchNegotiationConfig(context) {
      context.commit('mutateNegotiationConfig', null);
      axios.get('/api/negotiations/config', {
        headers: {
          'x-api-key': process.env.VUE_APP_TOKEN || '',
        },
      })
        .then((res) => {
          context.commit('mutateNegotiationConfig', res.data.data[0]);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Negotiation Config',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchFullCustomerIndex(context) {
      const params = {
        myAccounts: 0,
      };
      axios.get('/api/customers', {
        headers: {
          'x-api-key': process.env.VUE_APP_TOKEN || '',
        },
        params,
      })
        .then((res) => {
          context.commit('mutateFullCustomerIndex', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Get Customers',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchCustomerIndex(context, fromDashboard = false) {
      if (!fromDashboard) {
        context.commit('mutateCustomerIndex', null);
      }
      const params = fromDashboard ? {
        q: context.state.q,
        slim: 1,
      } : {
        q: context.state.q,
        rep: context.state.customerFilters.rep,
        type: context.state.customerFilters.type,
        from: context.state.customerFilters.from,
        to: context.state.customerFilters.to,
        showing: context.state.customerFilters.showing,
        myAccounts: context.state.customerFilters.myAccounts,
        myFavorites: context.state.customerFilters.myFavorites,
        territory: context.state.customerFilters.territory,
        grade: context.state.customerFilters.grade,
        tags: context.state.customerFilters.tags,
        x3_type: context.state.customerFilters.x3_type,
      };

      context.commit('mutateIsIndexFetching', true);
      axios.get('/api/customers', {
        headers: {
          'x-api-key': process.env.VUE_APP_TOKEN || '',
        },
        params,
      })
        .then((res) => {
          context.commit('mutateCustomerIndex', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Get Customers',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchExpandedCustomerIndex(context, query) {
      // set the fetching boolean
      context.commit('mutateSidebarCustomerListIsFetching', true);

      axios.get('/api/customers', {
        headers: {
          'x-api-key': process.env.VUE_APP_TOKEN || '',
        },
        params: new URLSearchParams(query),
      })
        .then((res) => {
          // mutate the advanced customer index
          context.commit('mutateCustomerIndex', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status || error,
            alertHeaderText: 'Request Failed - Get Customers',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        })
        .finally(() => {
          // data fetching is now complete
          context.commit('mutateSidebarCustomerListIsFetching', false);
        });
    },
    fetchCustomerContacts(context, customerID) {
      axios.get(`/api/customers/${customerID}/contacts/local`)
        .then((res) => {
          context.commit('mutateCustomerContacts', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Customer Contacts',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchCustomerHeaderInfo(context, customerID) {
      context.commit('mutateCustomerHeaderInfo', null);
      axios.get(`/api/customers/${customerID}/header`, {
        headers: {
          'x-api-key': process.env.VUE_APP_TOKEN || '',
        },
      })
        .then((res) => {
          context.commit('mutateCustomerHeaderInfo', res.data.data[0]);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Customer Header',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchFilteredCustomerInteractionsInfo(context, params) {
      context.commit('mutateCustomerInteractionsInfo', null);
      axios.get(`/api/customers/${params.id}/interactions`, {
        params: {
          q: params.q || null,
          rep: params.rep || null,
          type: params.type || null,
          from: params.from || null,
          to: params.to || null,
          attach: params.attach || null,
          page_size: params.pageSize || null,
        },
      })
        .then((res) => {
          context.commit('mutateCustomerInteractionsInfo', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Customer Interactions',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchCustomerInteractionsInfo(context, customerID) {
      context.commit('mutateCustomerInteractionsInfo', null);
      axios.get(`/api/customers/${customerID}/interactions`)
        .then((res) => {
          context.commit('mutateCustomerInteractionsInfo', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Customer Interactions',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchCustomerNegotiationsInfo(context, customerID) {
      context.commit('mutateCustomerNegotiationsInfo', null);
      axios.get(`/api/customers/${customerID}/negotiations`)
        .then((res) => {
          context.commit('mutateCustomerNegotiationsInfo', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Customer Negotiations',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchGrowerInteractionsInfo(context, customerID) {
      context.commit('mutateGrowerInteractionsInfo', null);
      axios.get(`/api/grower/${customerID}/interactions`)
        .then((res) => {
          context.commit('mutateGrowerInteractionsInfo', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Grower Interactions',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchCustomerRelationsInfo(context, customerID) {
      context.commit('mutateCustomerRelationsInfo', null);
      axios.get(`/api/customers/${customerID}/related`, {
        headers: {
          'x-api-key': process.env.VUE_APP_TOKEN || '',
        },
      })
        .then((res) => {
          context.commit('mutateCustomerRelationsInfo', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Customer Related',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchCurrentUser(context) {
      context.commit('mutateCurrentUser', null);
      axios.get('/api/user', {
        headers: {
          'x-api-key': process.env.VUE_APP_TOKEN || '',
        },
      })
        .then((res) => {
          context.commit('mutateCurrentUser', res.data.data);
          if (res.data.data) {
            Vue.prototype.$FullStory.identify(res.data.data.id, {
              displayName: `${res.data.data.first_name} ${res.data.data.last_name}`,
              email: res.data.data.email,
              uid: res.data.data.id,
            });
          }
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Current User',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchAllUsers(context, query = '') {
      axios.get(`/api/users/${query}`, {
        headers: {
          'x-api-key': process.env.VUE_APP_TOKEN || '',
        },
      })
        .then((res) => {
          context.commit('mutateAllUsersList', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - All Users',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchCustomerInformation(context, customerID) {
      context.commit('mutateCustomerInfo', null);
      axios.get(`/api/customers/${customerID}/orders`)
        .then((res) => {
          context.commit('mutateCustomerInfo', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Customer Profile',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchAdvancedCustomerProfile(context, customerID) {
      // nullify the existing customer profile data to prepare for the new payload
      // context.commit('mutateAdvancedCustomerProfile', null);
      // context.commit('mutateAssembledCustomerProfile', null);

      // fetch fresh customer profile
      axios.get(`/api/customers/${customerID}/profile`)
        .then((res) => {
          const data = res.data.data[0];

          // populate the advanced customer profile
          context.commit('mutateAdvancedCustomerProfile', data);

          // populate the assembled customer profile (goes through format processing)
          context.commit('mutateAssembledCustomerProfile', data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Customer Profile',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchInitialRequests(context) {
      context.dispatch('fetchCurrentUser');
      context.dispatch('fetchGlobalConfig');
      context.dispatch('fetchNegotiationConfig');
    },
    fetchUnloadedData(context, customerID) {
      context.dispatch('updateCurrentCustomer', customerID);
      context.dispatch('fetchCustomerHeaderInfo', customerID);
      if (context.state.customerHeaderInfo === null) {
        context.dispatch('fetchCustomerHeaderInfo', customerID);
      }
      if (context.state.globalConfig === null) {
        context.dispatch('fetchGlobalConfig');
      }
    },
    setFollowUpFilters(context, data) {
      context.commit('mutateFollowUpFilters', data);
    },
    fetchFollowupData(context, userID) {
      const params = context.state.followupFilters
        ? context.state.followupFilters
        : null;

      axios.get(`/api/users/${userID}/follow-ups`, { params })
        .then((res) => {
          if (res.data.success === true) {
            context.commit('mutateFollowupData', res.data.data);

            context.commit('mutateDashboardStatus', {
              target: 'followUpsLoaded',
              payload: true,
            });

            context.commit('mutateDashboardStatus', {
              target: 'followUpsError',
              payload: false,
            });
          } else {
            context.commit('mutateDashboardStatus', {
              target: 'followUpsLoaded',
              payload: false,
            });

            context.commit('mutateDashboardStatus', {
              target: 'followUpsError',
              payload: true,
            });
          }
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Follow-up Items',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });

          context.commit('mutateDashboardStatus', {
            target: 'followUpsLoaded',
            payload: false,
          });

          context.commit('mutateDashboardStatus', {
            target: 'followUpsError',
            payload: true,
          });
        });
    },
    fetchAllX3Types(context) {
      axios.get('/api/apiv3-proxy/x3-types')
        .then((res) => {
          context.commit('mutateX3Types', res.data.data);
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - X3 Types',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. '
              + 'Please refresh and try again. If this issue persists, contact IT.',
          });
        });
    },
    updateCurrentCustomer(context, customerID) {
      context.commit('mutateCurrentCustomer', customerID);
    },
    handleErrorResponse(context, data) {
      switch (data.statusCode) {
        case data.statusCode === 401: // User is not authenticated, kick to login
          context.commit('mutateIsLoggedIn', false);
          break;
        case data.statusCode === 500: // Internal server error, stay on page, but show error
        default:
          context.commit('mutateGlobalAlertConfig', {
            show: true,
            headerText: data.alertHeaderText,
            text: data.alertBodyText,
          });
          break;
      }
    },
    updateCustomerFocusOnMap(context, data) {
      context.commit('mutateCustomerFocusOnMap', data);
    },
    fetchUserNotifications(context, userID) {
      axios.get(`/api/users/${userID}/notifications`)
        .then((res) => {
          if (res.data.success === true) {
            if (res.data.data !== this.state.currentUserNotifications) {
              context.commit('mutateCurrentUserNotifications', res.data.data);
            }

            context.commit('mutateDashboardStatus', {
              target: 'notificationsLoaded',
              payload: true,
            });

            context.commit('mutateDashboardStatus', {
              target: 'notificationsError',
              payload: false,
            });
          } else {
            context.commit('mutateDashboardStatus', {
              target: 'notificationsLoaded',
              payload: false,
            });

            context.commit('mutateDashboardStatus', {
              target: 'notificationsError',
              payload: true,
            });
          }
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Notifications',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });

          context.commit('mutateDashboardStatus', {
            target: 'notificationsLoaded',
            payload: false,
          });

          context.commit('mutateDashboardStatus', {
            target: 'notificationsError',
            payload: true,
          });
        });
    },
    fetchUserNotificationSettings(context, userID) {
      context.commit('mutateCurrentUserNotificationSettings', null);

      axios.get(`/api/users/${userID}/notification-settings`)
        .then((res) => {
          if (res.data.success === true) {
            context.commit('mutateCurrentUserNotificationSettings', res.data.data);
          }
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Notification Settings',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    fetchUserNotificationPreferences(context, userID) {
      context.commit('mutateCurrentUserNotificationPreferences', null);

      axios.get(`/api/users/${userID}/notification-preferences`)
        .then((res) => {
          if (res.data.success === true) {
            // cherry-pick the items from the object that we want
            const picked = (({
              // eslint-disable-next-line camelcase
              associated_customers, subscribed_customers,
            }) => ({ associated_customers, subscribed_customers }))(res.data.data);

            context.commit('mutateCurrentUserNotificationPreferences', picked);
          }
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - Notification Preferences',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });
        });
    },
    setMapState(context, mapState) {
      context.commit('mutateMapIsReady', mapState);
    },
    fetchCurrentUserMetrics(context, params = {}) {
      // reset the metrics before fetch
      context.commit('mutateCurrentUserMetrics', null);

      // reset the metrics loading state
      context.commit('mutateMetricsAreLoading', true);

      axios.get('/api/user/metrics', { params })
        .then((res) => {
          if (res.data.success === true) {
            context.commit('mutateCurrentUserMetrics', res.data.data);

            // Metrics state
            context.commit('mutateDashboardStatus', {
              target: 'metricsLoaded',
              payload: true,
            });

            context.commit('mutateDashboardStatus', {
              target: 'metricsError',
              payload: false,
            });

            // Freshness state
            context.commit('mutateDashboardStatus', {
              target: 'freshnessLoaded',
              payload: true,
            });

            context.commit('mutateDashboardStatus', {
              target: 'freshnessError',
              payload: false,
            });
          } else {
            // Metrics state
            context.commit('mutateDashboardStatus', {
              target: 'metricsLoaded',
              payload: false,
            });

            context.commit('mutateDashboardStatus', {
              target: 'metricsError',
              payload: true,
            });

            // Freshness state
            context.commit('mutateDashboardStatus', {
              target: 'freshnessLoaded',
              payload: false,
            });

            context.commit('mutateDashboardStatus', {
              target: 'freshnessError',
              payload: true,
            });
          }
        })
        .catch((error) => {
          context.dispatch('handleErrorResponse', {
            statusCode: error.response.status,
            alertHeaderText: 'Request Failed - User Metrics',
            alertBodyText: 'Oops! An error occurred while retrieving data from the server. Please refresh '
              + 'and try again. If this issue persists, contact IT.',
          });

          // Metrics state
          context.commit('mutateDashboardStatus', {
            target: 'metricsLoaded',
            payload: false,
          });

          context.commit('mutateDashboardStatus', {
            target: 'metricsError',
            payload: true,
          });

          // Freshness state
          context.commit('mutateDashboardStatus', {
            target: 'freshnessLoaded',
            payload: false,
          });

          context.commit('mutateDashboardStatus', {
            target: 'freshnessError',
            payload: true,
          });
        })
        .finally(() => {
          // set the metrics loading state to false
          context.commit('mutateMetricsAreLoading', false);
        });
    },
  },
  getters: {
    getLoggedInStatus: (state) => state.isLoggedIn,
    customerNegotiationsInfo: (state) => state.customerNegotiationsInfo,
    getCustomers: (state) => (fragment) => {
      // eslint-disable-next-line prefer-const
      let filteredCustomers = [];
      state.customerIndex.forEach((customer) => {
        if (customer.legal_name.toLowerCase().includes(fragment.toLowerCase())
          || customer.doing_business_as.toLowerCase().includes(fragment.toLowerCase())) {
          filteredCustomers.push(customer);
        }
      });
      return filteredCustomers;
    },
    getCustomerIndex: (state) => state.customerIndex,
    getFullCustomerIndex: (state) => state.fullCustomerIndex,
    getTotalCustomerCount: (state) => state.fullCustomerTotal,
    getCurrentCustomer: (state) => state.currentCustomer,
    getCheckAuthInterval: (state) => () => state.checkAuthInterval,
    getLoginTimeout: (state) => () => state.loginTimeout,
    getCurrentUser: (state) => () => {
      let user = null;
      if (state.currentUser) {
        user = state.currentUser;
      }
      return user;
    },
    getInteractionTypes: (state) => () => {
      if (state.globalConfig) {
        return state.globalConfig.customer_interaction_types;
      }
      return [];
    },
    getSalesSites: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.sales_sites;
      }
      return [];
    },
    getCurrencies: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.currencies;
      }
      return [];
    },
    getPaymentTerms: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.payment_terms;
      }
      return [];
    },
    getIncoterms: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.incoterms;
      }
      return [];
    },
    getUnitsOfMeasure: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.units_of_measure;
      }
      return [];
    },
    getCropYears: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.years;
      }
      return [];
    },
    getSelectedOptions: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.selection_types;
      }
      return [];
    },
    getContractProductLines: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.product_lines;
      }
      return [];
    },
    getVarieties: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.varieties;
      }
      return [];
    },
    getPackSizes: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.pack_sizes;
      }
      return [];
    },
    getAnalysisMethods: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.analysis_methods;
      }
      return [];
    },
    getOutcomes: (state) => () => {
      if (state.negotiationConfig) {
        return state.negotiationConfig.outcomes;
      }
      return [];
    },
    getIsDirtyCustomerFilters: (state) => () => state.q !== null
      || (state.customerFilters.rep !== null
        && state.customerFilters.rep !== state.currentUser.id)
      || state.customerFilters.grade !== null
      || state.customerFilters.to !== null
      || state.customerFilters.from !== null
      || state.customerFilters.tags !== null
      || state.customerFilters.territory !== null,
    getModalOpen: (state) => () => state.interactionModalOpen,
    getCustomerFocusOnMap: (state) => state.customerFocusOnMap,
    getAdvancedCustomerProfile: (state) => state.advancedCustomerProfile,
    getAssembledCustomerProfile: (state) => state.assembledCustomerProfile,
    getCurrentUserNotifications: (state) => state.currentUserNotifications,
    getCurrentUserNotificationSettings: (state) => state.currentUserNotificationSettings,
    getCurrentUserNotificationPreferences: (state) => state.currentUserNotificationPreferences,
    getAdvancedCustomerFilters: (state) => state.advancedCustomerFilters,
    getMyAccountsToggleState: (state) => state.myAccountsInputVal,
    getMyFavoritesToggleState: (state) => state.myFavoritesInputVal,
    getAllUsers: (state) => state.allUsers,
    getFollowUpFilters: (state) => state.followupFilters,
    getFollowUpDefaults: (state) => {
      if (state.globalConfig) {
        return state.globalConfig.follow_up_defaults;
      }
      return [];
    },
    getAdvancedCustomerFiltersApplied: (state) => state.advancedCustomerFiltersApplied,
    getMapState: (state) => state.mapIsReady,
    getCustomerHeaderInfo: (state) => state.customerHeaderInfo,
    getCustomerModalRangeResetKey: (state) => state.customerModalRangeResetKey,
    getCurrentUserMetrics: (state) => state.currentUserMetrics,
    getMetricsLoadingState: (state) => state.userMetricsLoading,
    getAppliedFiltersMatchStored: (state) => state.appliedFiltersMatchStored,
    getSidebarCustomerListIsFetching: (state) => state.sidebarCustomerListIsFetching,
    getCustomerFilterFormIsMounted: (state) => state.customerFilterFormIsMounted,
  },
});
